import React from "react";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticStyles from "ps-assets/jss/customStaticPageStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import ThankYouBanner from "ps-assets/img/thank-you-banner.jpg";
import {  browserWindow } from "util/localStorageHelper";

class ThankYou extends React.Component {
  state = { customElement: null };

  getUTMObject = () => {
    let queryParams = decodeURIComponent(browserWindow.location.search);
    queryParams = queryParams.split(`?`)[1];
    queryParams = queryParams.split(`&`);
    let utmObject = {};
    queryParams.map((param) => {
      utmObject[param.split(`=`)[0]] = param.split(`=`)[1];
    });
    return utmObject;
  }

  createDefaultElement = () => {
    try{
    let utmObject = this.getUTMObject();
    let prevLandingPage = sessionStorage.getItem(`prevLandingPage`);
    switch(prevLandingPage){
      case `free-health-quote`:
        return [
          React.createElement("img", {
            src: `https://tracking.geoadmedia.com/aff_l?offer_id=1541&adv_sub=${
              utmObject[`userId`]
            }`,
            width: `1`,
            height: `1`,
          }),
          React.createElement("img", {
            src: `https://www.s2d6.com/x/?x=sp&h=71756&o=${
              utmObject[`userId`]
            }&g=&s=0.00&q=1`,
            width: "0",
            height: "0",
          }),
          React.createElement("img", {
            src: `https://mydigiera.com/p.ashx?o=698&e=29&f=img&udid=${
              utmObject[`userId`]
            }`,
            width: "1",
            height: "1",
            border: "0",
          }),
          React.createElement("img", {
            src: `https://ttrk.ringocount.com/pixel?adid=5e37ef27b6920d5f8d4171a4&txn_id=${
              utmObject[`userId`]
            }<https://ttrk.ringocount.com/pixel?adid=5e37ef27b6920d5f8d4171a4&txn_id=${utmObject[`userId`]}><https://urldefense.proofpoint.com/v2/url?u=https-3A__ttrk.ringocount.com_pixel-3Fadid-3D5e37ef27b6920d5f8d4171a4-26txn-5Fid-3DUNIQUE-5FID&d=DwMFaQ&c=Hso5VlsiAbVjjQbjbkggFA&r=Fo2fk5vyawhcFnLp63r23lWbV4IuFf1ybOUOA2pLmfE&m=pd1IuSz-HF8Vaf-N9awUa4rlu6qkVH3QYJ3YLVOfYiM&s=prFk-53YHQWPCbRNFuNt2UMB_eQJcKMBlX2XGmHpqRI&e=>`,
          }),
          React.createElement("img",{
            src:`https://www.intellectadz.com/track/conversion.asp?cid=1820&conversionType=1&key=${utmObject[`userId`]}&opt1=&opt2=&opt3=<https://www.intellectadz.com/track/conversion.asp?cid=1820&conversionType=1&key=${utmObject[`userId`]}&opt1=&opt2=&opt3=>`,
            height:`1`,
            width:`1`
          })
        ];
    }
  }
  catch(exception){
    console.error(exception);
  }
  }

  createCustomElement = (
    elementType,
    srcUrl,
    scrolling,
    frameBorder,
    width,
    height,
    type,
    border
  ) => {
    let props = {};
    if(srcUrl) props.src = srcUrl;
    if (scrolling) props.scrolling = scrolling;
    if (frameBorder) props.frameBorder = frameBorder;
    if (width) props.width = width;
    if (height) props.height = height;
    if (type) props.type = type;
    if (border) props.border = border;
    let customElement = React.createElement(elementType,{...props});
    this.setState({ customElement });
  };

  componentDidMount = () => {
    try {
      let utmObject = this.getUTMObject();
      let tempUrl =
        (location.protocol == "https:" ? "https://" : "http://") +
        `money.dailyhunt.in/tracker?orderId=1590&key=1590-b19d0a41dd6a70ec8f97a1bcda287b44`;
      let userId = utmObject[`userId`];
      switch (utmObject[`utm_channel`].toLowerCase()) {
        case "direct": {
          browserWindow.dataLayer.push({
            event: "conversion",
            send_to: "AW-987779232/qGvuCNDn3nMQoKGB1wM",
          });
          switch (utmObject[`utm_source`].toLowerCase()) {
            case `non-google`: {
              switch (utmObject[`utm_campaign`].toLowerCase()) {
                case `netcore`:
                  this.createCustomElement(
                    `iframe`,
                    `https://tracking.affiliatehub.co.in/SL1dh?adv_sub=@${userId}`,
                    "no",
                    "0",
                    "1",
                    "1"
                  );
                  break;
                case `twine-digital`:
                  this.createCustomElement(
                    `iframe`,
                    `https://twinedigital.go2cloud.org/aff_l?offer_id=571&adv_sub=@${userId}`,
                    "no",
                    "0",
                    "1",
                    "1"
                  );
                  break;
                case `icubeswire`:
                  this.createCustomElement(
                    `iframe`,
                    `https://icubes.go2cloud.org/SL59D?adv_sub=@${userId}`,
                    "no",
                    "0",
                    "1",
                    "1"
                  );
                  break;
                case `adcanopus`:
                  this.createCustomElement(
                    `img`,
                    `https://adcanopus.go2cloud.org/aff_l?offer_id=34720&adv_sub=@${userId}`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `collegedunia`:
                  this.createCustomElement(
                    `img`,
                    `https://primedigital.go2cloud.org/aff_l?offer_id=3562&adv_sub=@${userId}`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `profiliad`:
                  this.createCustomElement(
                    `img`,
                    `https://track.vnative.com/pixel?adid=5af532a6b6920d55bf6381e1&sub1=@${userId}`
                  );
                  break;
                case `intellect-ads`:
                  this.createCustomElement(
                    `img`,
                    `https://www.intellectads.co.in/track/conversion.asp?cid=1278&conversionType=1&opt1=&opt2=&opt3=&key=@${userId}`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `optimidea`:
                  this.createCustomElement(
                    `img`,
                    `https://optimidea.go2cloud.org/aff_l?offer_id=963&adv_sub=@${userId}`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `valueleaf`:
                  this.createCustomElement(
                    `img`,
                    `https://tracking.salesleaf.com/aff_l?offer_id=1554&adv_sub=@${userId}`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `one-native-mobile`:
                case `one-native-desktop`:
                  this.createCustomElement(
                    `script`,
                    `https://smxindia.in/p.ashx?a=686&f=js&e=689&t=@${userId}`,
                    null,
                    null,
                    null,
                    null,
                    "text/javascript"
                  );
                  break;
                case `iavatarz`:
                  this.createCustomElement(
                    `img`,
                    `https://iavtarazdigital.go2cloud.org/aff_l?offer_id=1321&adv_sub=@${userId}`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `vcommission`:
                  this.createCustomElement(
                    `iframe`,
                    `https://tracking.vcommission.com/SL94i?adv_sub=@${userId}`,
                    "no",
                    "0",
                    "1",
                    "1"
                  );
                  break;
                case `apoxy-media`:
                  this.createCustomElement(
                    `img`,
                    `https://apoxymedia.net/p.ashx?o=333&e=101&f=img&t=@${userId}`,
                    null,
                    null,
                    "1",
                    "1",
                    null,
                    "0"
                  );
                  break;
                case `affle`:
                  this.createCustomElement(
                    `img`,
                    `https://o5o4o6.com/p.ashx?o=88376&e=3074&f=img&t=@${userId}`,
                    null,
                    null,
                    "1",
                    "1",
                    null,
                    "0"
                  );
                  break;
                case `hochads`:
                  this.createCustomElement(
                    `img`,
                    `https://elevemedia.go2cloud.org/aff_l?offer_id=1019&adv_sub=@${userId}<https://urldefense.proofpoint.com/v2/url?u=https-3A__elevemedia.go2cloud.org_aff-5Fl-3Foffer-5Fid-3D1019-26adv-5Fsub-3DSUB-5FID&d=DwMFaQ&c=Hso5VlsiAbVjjQbjbkggFA&r=doHmiyJL_5E5v7C7Yf_JnmG3_mfb8bvBMZyf3o-Em8w&m=vWf8uxwFwRuEESF5lmHYsebFnQYvdOWawL0iua0bfWc&s=s1RYTsTCisyU4m9W12ZTMyi0zth3Lh4nT2MHm7BS9TI&e=>`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `opicle`:
                  this.createCustomElement(
                    `img`,
                    `https://track.opicle.com/aff_l?offer_id=3306&adv_sub=@${userId}<https://urldefense.proofpoint.com/v2/url?u=https-3A__track.opicle.com_aff-5Fl-3Foffer-5Fid-3D3306-26adv-5Fsub-3DLEAD-5FID&d=DwMFaQ&c=Hso5VlsiAbVjjQbjbkggFA&r=doHmiyJL_5E5v7C7Yf_JnmG3_mfb8bvBMZyf3o-Em8w&m=oWj32zQzKgaQfbYEVLFTofCy-BFc-qiRZxrWWgcs9l4&s=bGbsc-uas7toilfNqPYIFOe0ZjwDrN9T13B8DcWEW-M&e=>`,
                    null,
                    null,
                    "1",
                    "1"
                  );
                  break;
                case `clicks2install`:
                  this.createCustomElement(
                    `img`,
                    `https://track.clicks2install.com/p?t=i&oid=369638&adv_sub1={<https://urldefense.proofpoint.com/v2/url?u=https-3A__track.clicks2install.com_p-3Ft-3Di-26oid-3D369638-26adv-5Fsub1-3D-257B&d=DwMFaQ&c=Hso5VlsiAbVjjQbjbkggFA&r=doHmiyJL_5E5v7C7Yf_JnmG3_mfb8bvBMZyf3o-Em8w&m=AZadlgK_ZvE7sSAIhQgUGAPL13fi1HnbQoErRFv9YVo&s=z_LjhovBmU0PApsSdQYIgWlsa6U8-QIJ7w7FG9L1vqs&e=>${userId}}&adv_sub2={Cities}`,
                    null,
                    null,
                    "0px",
                    "0px"
                  );
                  break;
                case `dailyhunt`:
                  this.createCustomElement(`img`, tempUrl);
                  break;
              }
            }
          }
        }
        break;
        case "tele": {
          browserWindow.dataLayer.push({
            event: "conversion",
            send_to: "AW-987779232/dPpXCMXdsnQQoKGB1wM",
          });
          switch (utmObject[`utm_source`].toLowerCase()) {
            case `non-google`: {
              switch (utmObject[`utm_campaign`].toLowerCase()) {
                case `netcore`:
                  this.createCustomElement(
                    `iframe`,
                    `https://tracking.affiliatehub.co.in/SL1dh?adv_sub=@${userId}`,
                    `no`,
                    `0`,
                    `1`,
                    `1`
                  );
                  break;
                case `twine-digital`:
                  this.createCustomElement(
                    `iframe`,
                    `https://twinedigital.go2cloud.org/aff_l?offer_id=571&adv_sub=${userId}`,
                    `no`,
                    `0`,
                    `1`,
                    `1`
                  );
                  break;
                case `icubeswire`:
                  this.createCustomElement(
                    `iframe`,
                    `https://icubes.go2cloud.org/SL59D?adv_sub=${userId}`,
                    `no`,
                    `0`,
                    `1`,
                    `1`
                  );
                  break;
                case `adcanopus`:
                  this.createCustomElement(
                    `img`,
                    `https://adcanopus.go2cloud.org/aff_l?offer_id=34720&adv_sub=${userId}`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `collegedunia`:
                  this.createCustomElement(
                    `img`,
                    `https://primedigital.go2cloud.org/aff_l?offer_id=3562&adv_sub=${userId}`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `profiliad`:
                  this.createCustomElement(
                    `img`,
                    `https://track.vnative.com/pixel?adid=5af532a6b6920d55bf6381e1&sub1=${userId}`
                  );
                  break;
                case `intellect-ads`:
                  this.createCustomElement(
                    `img`,
                    `https://www.intellectads.co.in/track/conversion.asp?cid=1278&conversionType=1&key=${userId}&opt1=&opt2=&opt3=`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `optimidea`:
                  this.createCustomElement(
                    `img`,
                    `https://optimidea.go2cloud.org/aff_l?offer_id=963&adv_sub=${userId}`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `valueleaf`:
                  this.createCustomElement(
                    `img`,
                    `https://tracking.salesleaf.com/aff_l?offer_id=1554&adv_sub=${userId}`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `one-native-mobile`:
                  this.createCustomElement(
                    `script`,
                    `https://smxindia.in/p.ashx?a=686&f=js&e=689&t=${userId}`,
                    null,
                    null,
                    null,
                    null,
                    `text/javascript`,
                    null
                  );
                  break;
                case `one-native-desktop`:
                  this.createCustomElement(
                    `script`,
                    `https://smxindia.in/p.ashx?a=686&f=js&e=689&t=@${userId}`,
                    null,
                    null,
                    null,
                    null,
                    `text/javascript`,
                    null
                  );
                  break;
                case `iavatarz`:
                  this.createCustomElement(
                    `img`,
                    `https://iavtarazdigital.go2cloud.org/aff_l?offer_id=1321&adv_sub=${userId}`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `vcommission`:
                  this.createCustomElement(
                    `iframe`,
                    `https://tracking.vcommission.com/SL94i?adv_sub=${userId}`,
                    `no`,
                    `0`,
                    `1`,
                    `1`
                  );
                  break;
                case `apoxy-media`:
                  this.createCustomElement(
                    `img`,
                    `https://apoxymedia.net/p.ashx?o=333&e=101&f=img&t=${userId}`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `affle`:
                  this.createCustomElement(
                    `img`,
                    `https://o5o4o6.com/p.ashx?o=88376&e=3074&f=img&t=${userId}`,
                    null,
                    null,
                    `1`,
                    `1`,
                    null,
                    `0`
                  );
                  break;
                case `hochads`:
                  this.createCustomElement(
                    `img`,
                    `https://elevemedia.go2cloud.org/aff_l?offer_id=1019&adv_sub=${userId}<https://urldefense.proofpoint.com/v2/url?u=https-3A__elevemedia.go2cloud.org_aff-5Fl-3Foffer-5Fid-3D1019-26adv-5Fsub-3DSUB-5FID&d=DwMFaQ&c=Hso5VlsiAbVjjQbjbkggFA&r=doHmiyJL_5E5v7C7Yf_JnmG3_mfb8bvBMZyf3o-Em8w&m=vWf8uxwFwRuEESF5lmHYsebFnQYvdOWawL0iua0bfWc&s=s1RYTsTCisyU4m9W12ZTMyi0zth3Lh4nT2MHm7BS9TI&e=>`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `opicle`:
                  this.createCustomElement(
                    `img`,
                    `https://track.opicle.com/aff_l?offer_id=3306&adv_sub=${userId}<https://urldefense.proofpoint.com/v2/url?u=https-3A__track.opicle.com_aff-5Fl-3Foffer-5Fid-3D3306-26adv-5Fsub-3DLEAD-5FID&d=DwMFaQ&c=Hso5VlsiAbVjjQbjbkggFA&r=doHmiyJL_5E5v7C7Yf_JnmG3_mfb8bvBMZyf3o-Em8w&m=oWj32zQzKgaQfbYEVLFTofCy-BFc-qiRZxrWWgcs9l4&s=bGbsc-uas7toilfNqPYIFOe0ZjwDrN9T13B8DcWEW-M&e=>`,
                    null,
                    null,
                    `1`,
                    `1`
                  );
                  break;
                case `clicks2install`:
                  this.createCustomElement(
                    `img`,
                    `https://track.clicks2install.com/p?t=i&oid=369638&adv_sub1={<https://urldefense.proofpoint.com/v2/url?u=https-3A__track.clicks2install.com_p-3Ft-3Di-26oid-3D369638-26adv-5Fsub1-3D-257B&d=DwMFaQ&c=Hso5VlsiAbVjjQbjbkggFA&r=doHmiyJL_5E5v7C7Yf_JnmG3_mfb8bvBMZyf3o-Em8w&m=AZadlgK_ZvE7sSAIhQgUGAPL13fi1HnbQoErRFv9YVo&s=z_LjhovBmU0PApsSdQYIgWlsa6U8-QIJ7w7FG9L1vqs&e=>${userId}}&adv_sub2={Cities}`,
                    null,
                    null,
                    `0px`,
                    `0px`
                  );
                  break;
                case `dailyhunt`:
                  this.createCustomElement(`img`, tempUrl);
                  break;
              }
            }
          }
        }
      }
    } catch (exception) {
      console.error(exception);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <InstantBuyLayout>
        {this.state.customElement}
        {this.createDefaultElement()}
        <GridContainer>
          <GridItem className={classes.messageContainer}>
            <GridItem>
              <h2 className={classes.title}>Thank You</h2>
            </GridItem>
            <GridItem>
              <h4 className={classes.description}>
                Thanks for sharing your contact details.Our representative will
                get in touch shortly.
              </h4>
            </GridItem>
          </GridItem>
          <GridItem className={classes.thankYouImage}>
            <img src={ThankYouBanner} alt="thank-you-banner-here" />
            <Hidden smUp>
              <GridItem className={classes.thankYouTitle}>
                <span>14 Years of</span>
                <br />
                <span>Protecting Health</span>
              </GridItem>
              <GridItem className={classes.thankYouBody}>
                <span>With the support from our Customers,</span>
                <br />
                <span>Agents and Channel Partners</span>
              </GridItem>
            </Hidden>
          </GridItem>
        </GridContainer>
      </InstantBuyLayout>
    );
  }
}

ThankYou.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(customStaticStyles)(ThankYou);
